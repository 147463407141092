import React, { useEffect, Fragment, useState } from 'react';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import ReactApexChart from 'react-apexcharts';
import { Card, CardContent } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    backgroundColor: "#ffffff",

  },

}));

const TotalRadial = props => {
  const { title = 'Title', value , scaleValue = 1237, color = "" } = props;

  const classes = useStyles();

  const getValue = (v) => value;



  const [series, setSeries] = useState([]);


  const [options, setOptions] = useState();

  useEffect(()=> {
    setSeries([(value / scaleValue) * 100]);
    setOptions({
      chart: {
        type: "radialBar",
      },
  
      // series: [67],
      colors: [color],
      plotOptions: {
        radialBar: {
          startAngle: -100,
          endAngle: 100,
          dataLabels: {
            name: {
              offsetY: -10,
              color: "#000",
              // fontSize: "30px"
            },
            value: {
              color: "#000",
              // fontSize: "30px",
              show: true,
              formatter: getValue,
            }
          }
        }
      },
      labels: [title]
    });
  }, [value, scaleValue]);


  return <Card>
    <CardContent>
      { value  && (<ReactApexChart
        type='radialBar'
        options={options}
        series={series} />)}
    </CardContent>
  </Card>



};

TotalRadial.propTypes = {

};

export default TotalRadial;
